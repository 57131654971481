<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <b-card-code>
                <validation-observer ref="simpleRules">
                  <form @submit.prevent="submitForm">
                    <b-row>
                      <b-col md="4">
                        <b-form-group>
                          <label>Project Name</label>
                          <v-select
                            v-model="projectname"
                            label="projectname"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None"
                            :options="projectOptions"
                          />
                        </b-form-group>
                      </b-col>
                      <!-- <b-col md="4">
                        <b-form-group>
                          <label>Plot </label>
                          <v-select v-model="plotno" label="name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None" :options="plotnoOptions"/>
                        </b-form-group>
                      </b-col> -->
                      <b-col md="4">
                        <b-form-group>
                          <label>Status</label>
                          <v-select
                            v-model="status"
                            label="name"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            placeholder="None"
                            :options="[
                              { name: 'Saledeed Done', id: 2 },
                              { name: 'Full Payment But No Saledeed', id: 1 },
                              { name: 'Payment Pending', id: 0 },
                            ]"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="4">
                        <b-form-group>
                          <label>Select Date</label>
                          <flat-pickr
                            class="form-control"
                            value=""
                            placeholder="Select Date"
                            :config="{
                              dateFormat: 'd/m/Y',
                              mode: 'range',
                            }"
                            @input="getdata($event)"
                            style="background-color: transparent"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        class="ml-2"
                        @click="searchData"
                      >
                        <feather-icon icon="SearchIcon" class="mr-50" />
                        <span class="align-middle">Search</span>
                      </b-button>
                    </div>
                  </form>
                </validation-observer>
              </b-card-code>
              <b-card-code>
                <report-table
                  :data="data"
                  :columns="status.id !== 2 ? fields : fieldsForDeed"
                />
              </b-card-code>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportTable from "../../../components/ReportTable.vue";
import axios from '@/components/axios';
import Table from "@/components/Table.vue";
import BCardCode from "@core/components/b-card-code";
import flatPickr from "vue-flatpickr-component";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Form from "@/components/form/Form.vue";
import moment from "moment";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormTextarea,
  BFormDatepicker,
  BImgLazy,
} from "bootstrap-vue";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import vSelect from "vue-select";
export default {
  components: {
    ReportTable,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardCode,
    Form,
    GoodTableColumnSearch,
  },
  data() {
    return {
      startdate: "",
      enddate: "",
      result: "",
      data: "",
      projectname: "",
      projectOptions: [],
      // plotno: "",
      // plotnoOptions: [],
      status: "",
      baseApi: process.env.VUE_APP_APIENDPOINT,
      accessToken: localStorage.getItem("accessToken"),
      fields: [
        {
          field: "project",
          label: "Project",
          // filterOptions: { enabled: true, placeholder: "Search Project" },
        },
        {
          field: "plotno",
          label: "Plot No",
          // filterOptions: { enabled: true, placeholder: "Search Plot No" },
        },
        {
          field: "plotamount",
          label: "Total Plot Amount",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: "Search Total Plot Amount",
          // },
        },
      ],
      fieldsForDeed: [
        {
          field: "project",
          label: "Project",
          // filterOptions: { enabled: true, placeholder: "Search Project" },
        },
        {
          field: "plotno",
          label: "Plot No",
          // filterOptions: { enabled: true, placeholder: "Search Plot No" },
        },
        {
          field: "plotamount",
          label: "Total Plot Amount",
          // filterOptions: {
          //   enabled: true,
          //   placeholder: "Search Total Plot Amount",
          // },
        },
        {
          field: "deedno",
          label: "Saledeed No",
          // filterOptions: { enabled: true, placeholder: "Search Saledeed No" },
        },
        {
          field: "deeddate",
          label: "Saledeed Date",
          // filterOptions: { enabled: true, placeholder: "Search date" },
        },
      ],
    };
  },
  mounted() {
    this.getProjectname();
  },
  methods: {
    getdata(selectedDates) {
      this.result = selectedDates.split(" to ");
      this.startdate = this.result[0];
      this.enddate = this.result[1];
    },
    async searchData() {
      this.$forceUpdate();
      const data = {
        project_id: this.projectname.id,
        startdate: this.startdate,
        enddate: this.enddate,
        action: this.status.id,
      };
      await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        data: JSON.stringify(data),
        url: `${this.baseApi}/saledeedreport`,
      }).then((response) => {
        this.data = response.data.data;
        this.data.map((item) => {
          item.project = item.project.projectname;
          item.deeddate = moment(item.deeddate).format("DD/MM/YYYY");
        });
      });
    },
    async getProjectname() {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        url: `${this.baseApi}/getProject`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.projectOptions = response.data.data;
        })
        .catch((error) => console.log(error, "error"));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
